// log
import store from "../store";

const fetchDataRequest = () => {
  return {
    type: "CHECK_DATA_REQUEST",
  };
};

const fetchDataSuccess = (payload) => {
  return {
    type: "CHECK_DATA_SUCCESS",
    payload: payload,
  };
};

const fetchDataFailed = (payload) => {
  return {
    type: "CHECK_DATA_FAILED",
    payload: payload,
  };
};

export const fetchData = () => {
  return async (dispatch) => {
    dispatch(fetchDataRequest());
    try {
      console.log(store.getState());

      let totalSupply = await store
        .getState()
        .blockchain.smartContract.methods.totalSupply()
        .call();

      let publicPaused = await store
        .getState()
        .blockchain.smartContract.methods.paused()
        .call();

      let whitelistPaused = await store
        .getState()
        .blockchain.smartContract.methods.whitelistPaused()
        .call();

      let whitelistPrice = await store
        .getState()
        .blockchain.smartContract.methods.whitelistPrice()
        .call();

      if (whitelistPrice > 0) {
        whitelistPrice = whitelistPrice / 1000000000000000000;
      }

      let whitelistMintLimit = await store
        .getState()
        .blockchain.smartContract.methods.WHITELIST_MINT_LIMIT()
        .call();

      const configResponse = await fetch("/config/config.json", {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      });
      const CONFIG = await configResponse.json();

      let whitelistMaxPerMint = CONFIG.WHITELIST_MAX_PER_WALLET;

      // let isWhitelisted = await store.getState()
      //  .blockchain.smartContract.methods.isWhitelisted(account).call();

      // let whitelistMaxPerWalletMint = await store.getState()
      //  .blockchain.smartContract.methods.WHITELIST_MAX_PER_WALLET().call()
      //  .then((receipt) => {
      //   if (receipt < whitelistMaxPerMint) {
      //     whitelistMaxPerMint = receipt;
      //   }
      // });

      let price = await store
        .getState()
        .blockchain.smartContract.methods.publicPrice()
        .call();

      if (price > 0) {
        price = price / 1000000000000000000;
      }

      // let maxPerMint = await store.getState()
      //  .blockchain.smartContract.methods.MAX_PER_MINT().call();

      let maxPerMint = await store
        .getState()
        .blockchain.smartContract.methods.MAX_PER_WALLET()
        .call();

      // let maxSupply = await store.getState()
      //  .blockchain.smartContract.methods.MAX_SUPPLY().call();

      let maxSupply = await store
        .getState()
        .blockchain.smartContract.methods.MAX_SUPPLY()
        .call();

      await store
        .getState()
        .blockchain.smartContract.methods.whitelistSalesMintedQty()
        .call()
        .then((receipt) => {
          if (whitelistMintLimit - receipt < whitelistMaxPerMint) {
            whitelistMaxPerMint = whitelistMintLimit - receipt;
          }
        });

      let account = store.getState().blockchain.account;

      let numberMinted = 0;
      let isWhitelisted = true;
      if (account) {
        if (!whitelistPaused) {
          isWhitelisted = CONFIG.WHITELISTED.find((element) => {
            return element.toLowerCase() === account.toLowerCase();
          });

          if (isWhitelisted == account) {
            isWhitelisted = true;
          }
        }

        numberMinted = await store
          .getState()
          .blockchain.smartContract.methods.numberMinted(account)
          .call();

        // if (maxPerMint - numberMinted < maxPerMint) {
        //   maxPerMint = maxPerMint - numberMinted;
        // }

        await store
          .getState()
          .blockchain.smartContract.methods.whitelistSalesMinterToTokenQty(
            account
          )
          .call()
          .then((receipt) => {
            if (
              CONFIG.WHITELIST_MAX_PER_WALLET - receipt <
              whitelistMaxPerMint
            ) {
              whitelistMaxPerMint = CONFIG.WHITELIST_MAX_PER_WALLET - receipt;
            }
          });
      }

      dispatch(
        fetchDataSuccess({
          totalSupply,
          publicPaused,
          whitelistPaused,
          whitelistPrice,
          whitelistMaxPerMint,
          whitelistMintLimit,
          isWhitelisted,
          price,
          maxPerMint,
          maxSupply,
          numberMinted,
        })
      );
    } catch (err) {
      console.log(err);
      dispatch(fetchDataFailed("Could not load data from contract."));
    }
  };
};
