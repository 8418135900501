import React, { useCallback, useEffect, useState } from "react";
import { stack as Menu } from "react-burger-menu";
import { useDispatch, useSelector } from "react-redux";
import { Divide as Hamburger } from "hamburger-react";

import { setup, connect } from "../redux/blockchain/blockchainActions.js";
import { fetchData } from "../redux/data/dataActions";
import LazyMinter from "./lazy_minter";

import AOS from "aos";
import "aos/dist/aos.css";

import instagram from "../assets/instagram.svg";
import twitter from "../assets/twitter.svg";
import opensea from "../assets/opensea.svg";
import discord from "../assets/discord.svg";

import landing_bg from "../assets/__landing_bg.jpg";
import initial_img from "../assets/initial.webp";
import legend_img from "../assets/legend.webp";
import roadmap from "../assets/roadmap.webp";
import competition from "../assets/competition.webp";
import team_ava from "../assets/team_ava.jpg";
import sample_img from "../assets/sample.jpg";

const Main = () => {
  const dispatch = useDispatch();

  const blockchain = useSelector((state) => state.blockchain);
  const data = useSelector((state) => state.data);
  const [claimingNft, setClaimingNft] = useState(false);
  const [feedback, setFeedback] = useState(`Click buy to mint your NFT.`);
  const [mintQty, setMintQty] = useState(1);
  const [maxMintQty, setMaxMintQty] = useState(1);

  const [navbarAtTop, setNavbarAtTop] = useState(true);
  const [isOpen, setIsOpen] = useState(false);
  // const [slide, setSlide] = useState(0);

  const [CONFIG, SET_CONFIG] = useState({
    CONTRACT_ADDRESS: "",
    SCAN_LINK: "",
    NETWORK: {
      NAME: "",
      SYMBOL: "",
      ID: 0,
    },
    NFT_NAME: "",
    SYMBOL: "",
    GAS_LIMIT: 0,
    INSTAGRAM_LINK: "",
    TWITTER_LINK: "",
    OPENSEA_LINK: "",
    DISCORD_LINK: "",
    SIGNING_DOMAIN_NAME: "",
    SIGNING_DOMAIN_VERSION: "1",
  });

  const claimNFTs = () => {
    if (data.whitelistPaused) {
      let cost = data.price;
      let gasLimit = CONFIG.GAS_LIMIT;
      let totalCostWei = String(cost * mintQty);
      let totalGasLimit = String(gasLimit * mintQty);
      console.log("Cost: ", totalCostWei);
      console.log("Gas limit: ", totalGasLimit);
      setFeedback(`Minting your ${CONFIG.NFT_NAME}...`);
      setClaimingNft(true);

      try {
        blockchain.smartContract.methods
          .publicSalesMint(mintQty)
          .send({
            gasLimit: String(totalGasLimit),
            to: CONFIG.CONTRACT_ADDRESS,
            from: blockchain.account,
            value: blockchain.web3.utils.toWei(totalCostWei, "ether"),
          })
          .once("error", (err) => {
            console.log(err);
            setFeedback("Sorry, something went wrong please try again later.");
            setClaimingNft(false);
          })
          .then((receipt) => {
            console.log(receipt);
            setFeedback(
              `WOW, the ${CONFIG.NFT_NAME} is yours! go visit Opensea.io to view it.`
            );
            setClaimingNft(false);
            dispatch(fetchData(blockchain.account));
          });
      } catch (err) {
        console.log(err);
        window.alert("Please Connect Your Wallet First!");
        setClaimingNft(false);
      }
    } else {
      let cost = data.whitelistPrice;
      let gasLimit = CONFIG.GAS_LIMIT;
      let totalCostWei = String(cost * mintQty);
      let totalGasLimit = String(gasLimit * mintQty);
      console.log("Cost: ", totalCostWei);
      console.log("Gas limit: ", totalGasLimit);
      setFeedback(`Minting your ${CONFIG.NFT_NAME}...`);
      setClaimingNft(true);

      // console.log(CONFIG.SIGNING_DOMAIN_NAME);
      // console.log(CONFIG.SIGNING_DOMAIN_VERSION);

      const minter = new LazyMinter(
        CONFIG.CONTRACT_ADDRESS,
        CONFIG.SIGNING_DOMAIN_NAME,
        CONFIG.SIGNING_DOMAIN_VERSION
      );
      (async () => {
        const sig = await minter.createVoucher(
          blockchain.account,
          CONFIG.WHITELIST_MAX_PER_WALLET
        );

        try {
          blockchain.smartContract.methods
            .whitelistSalesMint(
              mintQty,
              CONFIG.WHITELIST_MAX_PER_WALLET,
              sig.nonce,
              sig.signature
            )
            .send({
              gasLimit: String(totalGasLimit),
              to: CONFIG.CONTRACT_ADDRESS,
              from: blockchain.account,
              value: blockchain.web3.utils.toWei(totalCostWei, "ether"),
            })
            .once("error", (err) => {
              console.log(err);
              setFeedback(
                "Sorry, something went wrong please try again later."
              );
              setClaimingNft(false);
            })
            .then((receipt) => {
              console.log(receipt);
              setFeedback(
                `WOW, the ${CONFIG.NFT_NAME} is yours! go visit Opensea.io to view it.`
              );
              setClaimingNft(false);
              dispatch(fetchData(blockchain.account));
            });
        } catch (err) {
          console.log(err);
          window.alert("Please Connect Your Wallet First!");
          setClaimingNft(false);
        }
      })();
    }
  };

  const decrementMintQty = () => {
    let newMintQty = mintQty - 1;
    if (newMintQty < 1) {
      newMintQty = 1;
    }
    setMintQty(newMintQty);
  };

  const incrementMintQty = () => {
    if (data.numberMinted >= maxMintQty) {
      return;
    }
    let newMintQty = mintQty + 1;
    if (newMintQty > maxMintQty) {
      newMintQty = maxMintQty;
    }
    setMintQty(newMintQty);
  };

  const getConfig = async () => {
    const configResponse = await fetch("/config/config.json", {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });
    const config = await configResponse.json();
    SET_CONFIG(config);
  };

  useEffect(() => {
    getConfig();
  }, []);

  const loadBlockchain = useCallback(async () => {
    dispatch(setup());
  }, []);

  useEffect(() => {
    loadBlockchain();
  }, []);

  const getData = () => {
    if (
      blockchain.account !== "" &&
      blockchain.smartContract !== null &&
      !claimingNft
    ) {
      dispatch(fetchData(blockchain.account));
    }
  };

  useEffect(() => {
    const intervalId = setInterval(() => {
      getData();
    }, 5000);
    return () => clearInterval(intervalId);
  }, [blockchain.account, blockchain.smartContract]);

  useEffect(() => {
    let newMaxMintQty = data.maxPerMint;

    if (!data.whitelistPaused) {
      newMaxMintQty = data.whitelistMaxPerMint;
    }

    if (newMaxMintQty - data.numberMinted < newMaxMintQty) {
      setMaxMintQty(newMaxMintQty - data.numberMinted);
    } else {
      setMaxMintQty(newMaxMintQty);
    }
  }, [data]);

  const [faq, setFaq] = useState({
    0: false,
    1: false,
    2: false,
    3: false,
    4: false,
    5: false,
    6: false,
    7: false,
    8: false,
  });

  useEffect(() => {
    AOS.init();
  }, []);

  const openFaq = (idx) => {
    for (const key in faq) {
      if (key === idx.toString()) {
        setFaq({ ...faq, [key]: !faq[key] });
      }
    }
  };

  const changeNavbarBackground = () => {
    if (window.scrollY >= 10) {
      setNavbarAtTop(false);
    } else {
      setNavbarAtTop(true);
    }
  };

  useEffect(() => {
    changeNavbarBackground();
    // adding the event when scroll change background
    window.addEventListener("scroll", changeNavbarBackground);
  });

  const handleOnClose = () => {
    setIsOpen(!isOpen);
    document.body.classList[!isOpen ? "add" : "remove"](
      "h-screen",
      "overflow-hidden"
    );
  };

  return (
    <main className="bg-[#F4B8C2]">
      {isOpen && (
        <div className="flex md:hidden">
          <Menu
            isOpen={isOpen}
            onClose={handleOnClose}
            className="bg-white bg-opacity-90"
          >
            <div className="w-full">
              <div className="flex items-center justify-between p-1 pl-8">
                <h1 className="w-48 text-2xl shadow-none text-start font-justBubble md:text-4xl text-[#42c9c4]">
                  Bendy Brains
                </h1>
                <Hamburger
                  rounded
                  toggled={isOpen}
                  toggle={handleOnClose}
                  size={20}
                />
              </div>
            </div>
            <button
              onClick={handleOnClose}
              className="pl-8 my-4 text-xl text-[#2FE0B0] font-justBubble"
            >
              <a href="#vision" className="cursor-pointer hover:underline">
                Vision
              </a>
            </button>
            <button
              onClick={handleOnClose}
              className="pl-8 my-4 text-xl text-[#2FE0B0] font-justBubble"
            >
              <a href="#utility" className="cursor-pointer hover:underline">
                Utility
              </a>
            </button>
            <button
              onClick={handleOnClose}
              className="pl-8 my-4 text-xl text-[#2FE0B0] font-justBubble"
            >
              <a href="#mint" className="cursor-pointer hover:underline">
                Mint
              </a>
            </button>
            <button
              onClick={handleOnClose}
              className="pl-8 my-4 text-xl text-[#2FE0B0] font-justBubble"
            >
              <a href="#roadmap" className="cursor-pointer hover:underline">
                Roadmap
              </a>
            </button>
            <button
              onClick={handleOnClose}
              className="pl-8 my-4 text-xl text-[#2FE0B0] font-justBubble"
            >
              <a href="#competition" className="cursor-pointer hover:underline">
                Competition
              </a>
            </button>
            <button
              onClick={handleOnClose}
              className="pl-8 my-4 text-xl text-[#2FE0B0] font-justBubble"
            >
              <a href="#team" className="cursor-pointer hover:underline">
                Team
              </a>
            </button>
          </Menu>
        </div>
      )}
      <div
        className={`md:hidden flex fixed w-full z-30 items-center justify-between p-2.5 ${
          navbarAtTop ? "bg-transparent" : "bg-white bg-opacity-50"
        }`}
      >
        <Hamburger rounded toggled={isOpen} toggle={handleOnClose} size={25} />
        <h1 className="w-48 text-2xl shadow-none text-center font-justBubble md:text-4xl text-[#42c9c4]">
          Bendy Brains
        </h1>
        <div className="flex flex-row items-center justify-center my-4 space-x-4">
          <a
            aria-label="twitter"
            href={CONFIG.TWITTER_LINK}
            target="_blank"
            rel="noreferrer noopener"
            className="underline"
          >
            <img src={twitter} alt="-" className="cursor-pointer" />
          </a>
          <a
            aria-label="instagram"
            href={CONFIG.INSTAGRAM_LINK}
            target="_blank"
            rel="noreferrer noopener"
            className="underline"
          >
            <img src={instagram} alt="-" className="cursor-pointer" />
          </a>
          <a
            aria-label="opensea"
            href={CONFIG.OPENSEA_LINK}
            target="_blank"
            rel="noreferrer noopener"
            className="underline"
          >
            <img src={opensea} alt="-" className="cursor-pointer" />
          </a>
        </div>
      </div>
      <nav
        id="nav"
        className={`fixed z-10 flex-row items-center justify-center hidden w-full py-8 md:flex ${
          navbarAtTop ? "bg-transparent" : "bg-white bg-opacity-50"
        }`}
      >
        <div className="flex items-center justify-between text-lg font-bold md:w-5/6 xl:text-xl 2xl:text-2xl">
          <h1 className="w-48 text-2xl shadow-none text-start font-justBubble md:text-4xl text-[#42c9c4]">
            Bendy Brains
          </h1>
          <div className="grid items-center w-5/6 grid-cols-6 text-center text-xl text-[#2FE0B0]  xl:text-2xl 2xl:text-3xl font-justBubble">
            <a href="#vision" className="cursor-pointer hover:underline">
              Vision
            </a>
            <a href="#utility" className="cursor-pointer hover:underline">
              Utilty
            </a>
            <a href="#mint" className="cursor-pointer hover:underline">
              Mint
            </a>
            <a href="#roadmap" className="cursor-pointer hover:underline">
              Roadmap
            </a>
            <a href="#competition" className="cursor-pointer hover:underline">
              Competition
            </a>
            <a href="#team" className="cursor-pointer hover:underline">
              Team
            </a>
          </div>
          <div className="flex flex-row items-center justify-center my-4 space-x-4">
            <a
              aria-label="twitter"
              href={CONFIG.TWITTER_LINK}
              target="_blank"
              rel="noreferrer noopener"
              className="underline"
            >
              <img src={twitter} alt="-" className="cursor-pointer" />
            </a>
            <a
              aria-label="instagram"
              href={CONFIG.INSTAGRAM_LINK}
              target="_blank"
              rel="noreferrer noopener"
              className="underline"
            >
              <img src={instagram} alt="-" className="cursor-pointer" />
            </a>
            <a
              aria-label="opensea"
              href={CONFIG.OPENSEA_LINK}
              target="_blank"
              rel="noreferrer noopener"
              className="underline"
            >
              <img src={opensea} alt="-" className="cursor-pointer" />
            </a>
          </div>
        </div>
      </nav>
      <section id="vision" className="relative h-auto mb-10">
        <img src={landing_bg} className="object-contain pt-24 mx-auto" />
        <div className="flex items-center justify-center h-auto py-10">
          <div
            data-aos="fade-down"
            data-aos-easing="linear"
            data-aos-duration="1000"
            className="flex flex-col items-center justify-center w-10/12 text-center"
          >
            <h1 className="text-[#42c9c4] my-5 text-4xl text-center shadow-none font-justBubble md:text-6xl">
              Bendy Brains Vision
            </h1>
            <p className="w-full mt-5 font-bold text-base text-center md:w-2/3 xl:w-2/3 md:text-lg xl:text-xl 2xl:text-2xl">
              BendyBrains is a collection of 9,999 unique NFTs hanging out in
              the Ethereum blockchain. Owning a BendyBrain will grant access to
              an inclusive Web3 community and will also unlock all the perks on
              the roadmap and provide holders with future airdrops. Our
              BendyBrains collection seeks to draw attention to mental health
              and mental illness. Join us in making a difference in mental
              health through the wonders of Web3 and the blockchain!
            </p>
          </div>
        </div>
      </section>

      <section
        id="utility"
        className="flex items-center justify-center md:h-screen bg-center bg-banner-01 py-10 bg-no-repeat bg-cover md:py-20"
      >
        <div
          data-aos="fade-down"
          data-aos-easing="linear"
          data-aos-duration="1000"
          className="flex flex-col items-center justify-center w-10/12"
        >
          <h1 className="text-[#fff] my-5 text-4xl text-center shadow-none font-justBubble md:text-6xl">
            once initial collection sales
          </h1>
          <img src={initial_img} className="object-contain w-full" />
        </div>
      </section>

      <section id="art" className="relative h-auto">
        <img src={legend_img} className="object-contain mx-auto" />
        <div className="flex items-center justify-center h-auto py-10">
          <div
            data-aos="fade-down"
            data-aos-easing="linear"
            data-aos-duration="1000"
            className="flex flex-col items-center justify-center w-10/12 text-center"
          >
            <p className="w-full mt-5 font-bold text-base text-center md:w-1/3 xl:w-2/3 md:text-lg xl:text-xl 2xl:text-2xl">
              Within the 9,999 NFTs there are 100 legendary 1:1 ratio. Each of
              our NFTs have been digitally hand drawn. We are strong on
              transparency, every NFT will be randomly distributed once minted.
              There is no special preference.
            </p>
          </div>
        </div>
      </section>

      <section id="mint" className="text-white body-font">
        <div className="container flex px-5 py-28 mx-auto">
          <div className="flex flex-col-reverse w-full md:flex-row">
            <div className="hidden md:flex md:w-1/3">
              <img
                alt="gallery-1"
                className="block object-contain object-center w-1/2 h-auto ml-auto rounded-lg"
                src={team_ava}
              />
            </div>
            <div className="flex flex-col justify-center space-y-10 text-center  md:w-1/2 rounded-xl p-14">
              {(!data.publicPaused || !data.whitelistPaused) &&
              data.maxSupply > 0 &&
              data.numberMinted < data.maxPerMint &&
              blockchain.account ? (
                <div>
                  <h1 className="text-[#42c9c4] my-5 text-4xl text-center shadow-none font-justBubble md:text-6xl">
                    {data.totalSupply} / {data.maxSupply} Minted
                  </h1>
                  {(data.publicPaused && data.whitelistPaused) ||
                  (data.isWhitelisted && !data.whitelistPaused) ||
                  !data.publicPaused ? (
                    <div>
                      {data.whitelistPrice > 0 && (
                        <div className="my-2 text-center md:text-lg xl:text-xl 2xl:text-2xl">
                          {data.whitelistPrice} ETH each
                        </div>
                      )}
                    </div>
                  ) : (
                    !data.publicPaused && (
                      <div>
                        {data.price > 0 && (
                          <div className="my-2 text-center md:text-lg xl:text-xl 2xl:text-2xl">
                            {data.price} ETH each
                          </div>
                        )}
                      </div>
                    )
                  )}
                </div>
              ) : (
                <h1 className="text-[#42c9c4] my-5 text-4xl text-center shadow-none font-justBubble md:text-6xl">
                  Get Your BendyBrains
                </h1>
              )}
              {data.publicPaused && data.whitelistPaused ? (
                <div>
                  <a
                    href={CONFIG.OPENSEA_LINK}
                    target="_blank"
                    className="block w-full px-4 py-2 font-semibold text-white rounded cursor-pointer bg-[#42c9c4] hover:bg-[#339e9b]"
                    aria-label="opensea"
                  >
                    Visit Marketplace
                  </a>
                </div>
              ) : (
                <div>
                  {blockchain.account === null ? (
                    <div className="w-full">
                      <a
                        href="#mint"
                        aria-label="connect"
                        className="block w-full px-4 py-2 md:text-lg xl:text-xl 2xl:text-2xl font-semibold text-white rounded cursor-pointer bg-[#42c9c4] hover:bg-[#339e9b]"
                        onClick={(e) => {
                          dispatch(connect());
                        }}
                      >
                        Connect
                      </a>
                      {blockchain.errorMsg !== "" ? (
                        blockchain.errorMsg === "Install Metamask." ? (
                          <>
                            <a
                              href="https://metamask.io/download/"
                              className="block mr-5 text-xs text-teal-400 cursor-pointer lg:inline-block"
                            >
                              {blockchain.errorMsg}
                            </a>
                          </>
                        ) : (
                          <>
                            <p className="block mr-5 text-xs text-black cursor-pointer lg:inline-block">
                              {blockchain.errorMsg}
                            </p>
                          </>
                        )
                      ) : null}
                    </div>
                  ) : (
                    <div>
                      {(data.publicPaused && data.whitelistPaused) ||
                        (data.isWhitelisted &&
                          (!data.publicPaused || !data.whitelistPaused) &&
                          (!data.loading && !blockchain.loading ? (
                            data.numberMinted < data.maxPerMint ? (
                              <div>
                                <div className="w-full h-12 my-4 custom-number-input">
                                  <div className="relative flex flex-row w-full h-12 space-x-2 bg-transparent rounded-lg">
                                    <button
                                      data-action="decrement"
                                      className={`w-20 h-full text-gray-600 bg-white rounded-l outline-none cursor-pointer hover:text-gray-700 hover:bg-[#42c9c4]`}
                                      onClick={(e) => {
                                        decrementMintQty(e);
                                      }}
                                    >
                                      <span className="m-auto text-2xl font-thin">
                                        −
                                      </span>
                                    </button>
                                    <div
                                      className={`flex items-center justify-center w-full font-semibold text-center text-gray-700 bg-white outline-none focus:outline-none text-md hover:text-black focus:text-black md:text-base cursor-default`}
                                      id="custom-input-number"
                                    >
                                      {mintQty}
                                    </div>
                                    <button
                                      data-action="increment"
                                      className={`w-20 h-full text-gray-600 bg-white rounded-r cursor-pointer hover:text-gray-700 hover:bg-[#42c9c4]`}
                                      onClick={(e) => {
                                        incrementMintQty(e);
                                      }}
                                    >
                                      <span className="m-auto text-2xl font-thin">
                                        +
                                      </span>
                                    </button>
                                  </div>
                                </div>
                                <a
                                  href="#mint"
                                  className={`block w-full px-4 md:text-lg xl:text-xl 2xl:text-2xl py-2 font-semibold text-white rounded cursor-pointer bg-[#42c9c4] hover:bg-[#339e9b] ${
                                    !data.loading &&
                                    !blockchain.loading &&
                                    mintQty > 0
                                      ? "cursor-pointer"
                                      : "cursor-not-allowed"
                                  }`}
                                  aria-label="mint"
                                  onClick={(e) => {
                                    e.preventDefault();
                                    claimNFTs();
                                  }}
                                >{`Mint to ${
                                  blockchain.account.substr(0, 3) +
                                  ".." +
                                  blockchain.account.substr(-4)
                                }`}</a>

                                <div className="my-2 text-center md:text-lg xl:text-xl 2xl:text-2xl">
                                  Minted ({data.numberMinted} /{" "}
                                  {data.maxPerMint})
                                </div>
                              </div>
                            ) : (
                              <a
                                href={CONFIG.OPENSEA_LINK}
                                target="_blank"
                                className="block w-full px-4 py-2 md:text-lg xl:text-xl 2xl:text-2xl font-semibold text-white rounded cursor-pointer bg-[#42c9c4] hover:bg-[#339e9b]"
                                aria-label="opensea"
                              >
                                Visit Marketplace
                              </a>
                            )
                          ) : (
                            <a
                              href="#mint"
                              className={`block w-full px-4 py-2 md:text-lg xl:text-xl 2xl:text-2xl font-semibold text-white rounded cursor-pointer bg-[#42c9c4] hover:bg-[#339e9b] ${
                                data.loading || blockchain.loading
                                  ? "cursor-not-allowed"
                                  : "cursor-pointer"
                              }`}
                              aria-label="mint"
                            >
                              Loading..
                            </a>
                          )))}
                      {!data.isWhitelisted && (
                        <div>
                          <a
                            href={CONFIG.OPENSEA_LINK}
                            target="_blank"
                            className="block w-full px-4 py-2 md:text-lg xl:text-xl 2xl:text-2xl font-semibold text-white rounded cursor-pointer bg-[#42c9c4] hover:bg-[#339e9b]"
                            aria-label="opensea"
                          >
                            Visit Marketplace
                          </a>
                          <p className="inline-flex py-2 font-semibold text-center text-red-600 focus:outline-none">
                            Sorry, You are not whitelisted.
                          </p>
                        </div>
                      )}
                    </div>
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
      </section>

      <section
        id="roadmap"
        className="flex items-center justify-center md:h-screen bg-banner-01 py-10 bg-center bg-no-repeat bg-cover  md:py-20"
      >
        <div
          data-aos="fade-down"
          data-aos-easing="linear"
          data-aos-duration="1000"
          className="flex flex-col items-center justify-center w-10/12"
        >
          <h1 className="text-[#fff] my-5 text-4xl text-center shadow-none font-justBubble md:text-6xl">
            Bendy Brains Roadmap
          </h1>
          <img
            src={roadmap}
            className="object-contain w-4/5 sm:w-4/5 md:w-3/5 lg:w-3/5 xl:w-600 2xl:w-600"
          />
        </div>
      </section>

      <section className="text-white body-font ">
        <div className="container flex flex-col items-center justify-center w-2/3 px-5 py-12 mx-auto md:flex-row">
          <img
            className="object-cover object-center w-48 rounded md:ml-auto"
            alt="hero"
            src={sample_img}
          />
          <div className="lg:flex-grow md:w-1/2 lg:pl-24 md:pl-16 flex flex-col md:items-start md:text-left items-center text-center font-justBubble text-[#42c9c4]">
            <h1 className="mb-4 text-3xl font-medium title-font sm:text-4xl">
              Charity
            </h1>
            <p className="mb-8 text-xl leading-relaxed xl:text-xl 2xl:text-3xl">
              BendyBrains will be donating 10% of its primary sales to a mental
              health charity that focuses on mental health and mental illness.
            </p>
          </div>
        </div>
      </section>

      <section id="competition" className="md:h-screen  py-12">
        <div
          data-aos="fade-down"
          data-aos-easing="linear"
          data-aos-duration="1000"
          className="flex flex-col items-center justify-center h-full text-center"
        >
          <h1 className="text-[#42c9c4] my-5 text-4xl text-center shadow-none font-justBubble md:text-6xl">
            Community Competitions
          </h1>
          <img src={competition} className="object-contain w-5/6 md:w-2/3" />
        </div>
      </section>

      <section
        id="team"
        className="h-auto text-white  body-font md:text-lg xl:text-xl  2xl:text-2xl"
      >
        <div className="container px-5 py-24 mx-auto">
          <h1 className="text-[#42c9c4] my-5 text-4xl text-center shadow-none font-justBubble md:text-6xl">
            Our Team
          </h1>
          <div className="flex flex-wrap mx-auto text-center md:-m-4">
            <div className="w-full p-4 mb-6 lg:w-1/3 lg:mb-0">
              <div className="h-full text-center">
                <img src={team_ava} alt="-" className="w-48 mx-auto mb-8" />
                <p className="text-2xl shadow-none text-start">Jose Soriano</p>
                <p className="my-2 font-bold">Founder, Artist</p>
                {/* <p className="w-1/2 mx-auto font-medium tracking-wider text-white title-font">The Leader of AVGG & GorillaGang. The visionary who brings this all together.</p> */}
              </div>
            </div>
            <div className="w-full p-4 mb-6 lg:w-1/3 lg:mb-0">
              <div className="h-full text-center">
                <img src={team_ava} alt="-" className="w-48 mx-auto mb-8" />
                <p className="text-2xl shadow-none text-start">
                  Malaquias Marconi
                </p>
                <p className="my-2 font-bold">Founder, Head of Operations</p>
                {/* <p className="w-1/2 mx-auto font-medium tracking-wider text-white title-font">Spend half of his life in developing TV show and entertainment business. He got all the experiences that needed to make an entertaining game and Metaverse.</p> */}
              </div>
            </div>
            <div className="w-full p-4 lg:w-1/3 lg:mb-0">
              <div className="h-full text-center">
                <img src={team_ava} alt="-" className="w-48 mx-auto mb-8" />
                <p className="text-2xl shadow-none text-start">Moses Marquez</p>
                <p className="my-2 font-bold">Founder, Blockchain Engineer</p>
                {/* <p className="w-1/2 mx-auto font-medium tracking-wider text-white title-font">Design is his passion from the day he learned what he can create with art and technology combined.</p> */}
              </div>
            </div>
          </div>

          <div className="flex flex-col items-center justify-center w-5/6 pt-12 mx-auto text-center md:w-4/5">
            <h1 className="text-[#42c9c4] my-2 text-4xl text-center shadow-none font-justBubble md:text-6xl">
              Who Are We?
            </h1>
            <p className="mt-5 font-bold text-center md:text-lg xl:text-xl 2xl:text-2xl">
              We are 3 lifelong friends who have many different interests and
              hobbies but what we have in common, is our passion, drive and
              focus to highlighting the importance of mental health and mental
              illness. We come from different backgrounds focused on one goal.
              <br />
              <br /> Together the three of us have formulated an NFT collection
              with a purpose and utility. We want our community to feel safe and
              be successful in this endeavor into web3.
              <br />
              <br /> A big thing we want to focus on is transparency. As
              everyone knows, there are parties out in the world who focus on
              harming instead of helping and we want to make sure everyone knows
              who we are, what we plan on doing and we want to hold ourselves
              accountable to our word.
            </p>
          </div>
        </div>
      </section>

      <section className="relative text-white  body-font">
        <div className="container flex flex-col items-center px-5 py-24 mx-auto md:flex-row">
          <div className="w-4/5 mb-10 md:w-1/5 md:mb-0">
            <h1 className="text-[#42c9c4] my-2 text-4xl text-center shadow-none font-justBubble md:text-6xl">
              Bendy Brains
            </h1>
          </div>
          <div className="flex flex-col items-center text-center lg:flex-grow md:w-1/2 lg:pl-24 md:pl-16 md:items-start md:text-left">
            <div className="my-2 text-center md:text-lg xl:text-xl 2xl:text-2xl">
              BendyBrains Smart Contract
            </div>

            <div className="w-auto">
              <a
                href={CONFIG.SCAN_LINK}
                aria-label="scan"
                target="_blank"
                className="inline-block px-6 py-2 font-semibold text-center md:text-lg xl:text-xl 2xl:text-2xl text-white rounded cursor-pointer w-full bg-[#42c9c4] hover:bg-[#339e9b]"
              >{`${
                CONFIG.CONTRACT_ADDRESS.substr(0, 20) +
                ".." +
                CONFIG.CONTRACT_ADDRESS.substr(-12)
              }`}</a>
            </div>

            <div className="flex flex-row items-center justify-center my-4 space-x-4 xl:text-ls 2xl:text-xl">
              <div className="px-3.5 text-center py-4 bg-[#e58a99] rounded-lg">
                <div className="py-4 text-center bg-[#F4B8C2] rounded-lg px-9">
                  <a
                    aria-label="twitter"
                    href={CONFIG.TWITTER_LINK}
                    target="_blank"
                    rel="noreferrer noopener"
                    className="px-3.5 py-4 bg-none"
                  >
                    <img
                      src={twitter}
                      alt="-"
                      className="w-16 h-16 text-white cursor-pointer"
                    />
                  </a>
                </div>
                <p className="pt-4">Twitter</p>
              </div>

              <div className="px-3.5 text-center py-4 bg-[#e58a99] rounded-lg">
                <div className="py-4 text-center bg-[#F4B8C2] rounded-lg px-9">
                  <a
                    aria-label="discord"
                    href={CONFIG.DISCORD_LINK}
                    target="_blank"
                    rel="noreferrer noopener"
                    className="px-3.5 py-4 bg-none"
                  >
                    <img
                      src={discord}
                      alt="-"
                      className="w-16 h-16 text-white cursor-pointer"
                    />
                  </a>
                </div>
                <p className="pt-4">Discord</p>
              </div>

              <div className="px-3.5 text-center py-4 bg-[#e58a99] rounded-lg">
                <div className="py-4 text-center bg-[#F4B8C2] rounded-lg px-9">
                  <a
                    aria-label="opensea"
                    href={CONFIG.OPENSEA_LINK}
                    target="_blank"
                    rel="noreferrer noopener"
                    className="px-3.5 py-4 bg-none"
                  >
                    <img
                      src={opensea}
                      alt="-"
                      className="w-16 h-16 text-white cursor-pointer"
                    />
                  </a>
                </div>
                <p className="pt-4">Marketplace</p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </main>
  );
};

export default Main;
