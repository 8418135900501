const initialState = {
  loading: false,
  totalSupply: 0,
  publicPaused: false,
  whitelistPaused: false,
  whitelistPrice: 0,
  whitelistMaxPerMint: 0,
  whitelistMintLimit: 0,
  isWhitelisted: true,
  price: 0,
  maxPerMint: 0,
  maxSupply: 0,
  numberMinted: 0,
  error: false,
  errorMsg: "",
};

const dataReducer = (state = initialState, action) => {
  switch (action.type) {
    case "CHECK_DATA_REQUEST":
      return {
        ...state,
        loading: true,
        error: false,
        errorMsg: "",
      };
    case "CHECK_DATA_SUCCESS":
      console.log("Fetching..");
      // console.log(action.payload);
      return {
        ...state,
        loading: false,
        totalSupply: action.payload.totalSupply,
        publicPaused: action.payload.publicPaused,
        whitelistPaused: action.payload.whitelistPaused,
        whitelistPrice: action.payload.whitelistPrice,
        whitelistMaxPerMint: action.payload.whitelistMaxPerMint,
        whitelistMintLimit: action.payload.whitelistMintLimit,
        isWhitelisted: action.payload.isWhitelisted,
        price: action.payload.price,
        maxPerMint: action.payload.maxPerMint,
        maxSupply: action.payload.maxSupply,
        numberMinted: action.payload.numberMinted,
        error: false,
        errorMsg: "",
      };
    case "CHECK_DATA_FAILED":
      return {
        ...initialState,
        loading: false,
        error: true,
        errorMsg: action.payload,
      };
    default:
      return state;
  }
};

export default dataReducer;
