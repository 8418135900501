const initialState = {
  loading: false,
  account: null,
  smartContract: null,
  web3: null,
  provider: null,
  web3Provider: null,
  chainId: null,
  errorMsg: "",
};

const blockchainReducer = (state = initialState, action) => {
  switch (action.type) {
    case "CONNECTION_REQUEST":
      return {
        ...initialState,
        loading: true,
      };
    case "CONNECTION_SETUP":
      return {
        ...state,
        loading: false,
        smartContract: action.payload.smartContract,
        web3: action.payload.web3,
      };
    case "CONNECTION_SUCCESS":
      return {
        ...state,
        loading: false,
        account: action.payload.account,
        smartContract: action.payload.smartContract,
        web3: action.payload.web3,
      };
    case "CONNECTION_FAILED":
      return {
        ...initialState,
        loading: false,
        errorMsg: action.payload,
      };
    case "UPDATE_ACCOUNT":
      return {
        ...state,
        account: action.payload.account,
      };
    case "SET_WEB3_PROVIDER":
      return {
        ...state,
        provider: action.payload.provider,
        web3Provider: action.payload.web3Provider,
        address: action.payload.account,
        chainId: action.payload.chainId,
      };
    case "SET_ADDRESS":
      return {
        ...state,
        address: action.payload.account,
      };
    case "SET_CHAIN_ID":
      return {
        ...state,
        chainId: action.payload.chainId,
      };
    case "RESET_WEB3_PROVIDER":
      return initialState;
    default:
      return state;
  }
};

export default blockchainReducer;
