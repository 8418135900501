import { ethers } from "ethers";

/**
 * JSDoc typedefs.
 *
 * @typedef {object} Whitelist
 * @property {ethers.BigNumber | number} tokenId the id of the un-minted NFT
 * @property {ethers.Signer.getAddress()} recipient the recipient address to redeem this NFT
 * @property {ethers.BytesLike} signature an EIP-712 signature of all fields in the NFTVoucher, apart from signature itself
 */

/**
 * LazyMinter is a helper class that creates NFTVoucher objects and signs them, to be redeemed later by the FashionNFT contract.
 */
class LazyMinter {
  /**
   * Create a new LazyMinter targeting a deployed instance of the FashionNFT contract.
   *
   * @param {Object} options
   * @param {ethers.Signer.getAddress()} contract the account that's wired up to the deployed contract
   * @param {string} domainName
   * @param {string} domainVersion
   */
  constructor(contract, domainName, domainVersion) {
    this.contract = contract;
    this.domainName = domainName;
    this.domainVersion = domainVersion;

    const provider = new ethers.providers.Web3Provider(window.ethereum);

    // a signer whose account is authorized to mint NFTs on the deployed contract
    this.signer = new ethers.Wallet(
      process.env.REACT_APP_SIGNER_PRIVATE_KEY,
      provider
    );
  }

  /**
   * Creates a new NFTVoucher object and signs it using this LazyMinter's signing key.
   *
   * @param {ethers.Signer.getAddress()} buyer the account to redeem this NFT
   * @param {ethers.BigNumber | number} signedQty the signed quantity
   *
   * @returns {Whitelist}
   */
  createVoucher = async (buyer, signedQty) => {
    var nonce = Date.now() + 100000;
    const voucher = { buyer, signedQty, nonce };
    const domain = await this._signingDomain();
    const types = {
      Whitelist: [
        { name: "buyer", type: "address" },
        { name: "signedQty", type: "uint256" },
        { name: "nonce", type: "uint256" },
      ],
    };
    const signature = await this.signer._signTypedData(domain, types, voucher);

    return {
      ...voucher,
      signature,
    };
  };

  /**
   * @private
   * @returns {object} the EIP-721 signing domain, tied to the chainId of the signer
   */
  _signingDomain = async () => {
    if (this._domain != null) {
      return this._domain;
    }
    const provider = new ethers.providers.Web3Provider(window.ethereum);
    let { chainId } = await provider.getNetwork();
    this._domain = {
      name: this.domainName,
      version: this.domainVersion,
      verifyingContract: this.contract,
      chainId,
    };
    return this._domain;
  };
}

export default LazyMinter;
